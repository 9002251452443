.react-datepicker-wrapper {
  visibility: hidden;
  position: absolute;
  left: 0;
  width: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  background-color: #94d82d;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #94d82d;
}

.react-datepicker__day--highlighted-installation-date {
  background-color: yellow;
}
